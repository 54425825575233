<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active">
            <router-link :to="{ name: 'home' }">Home</router-link>
          </li>
          <li><a href="javascript:void(0);">Contact Us</a></li>
        </ul>
      </div>
    </div>
    <section class="section--contact ps-page--business bg-white">
      <div class="container">
        <h2 class="page__title">Contact Us</h2>
        <div class="contact__content">
          <div class="row">
            <div class="col-12 col-lg-7">
              <div class="row">
                <div class="col-12">
                  <Map />

                    <hr/>
                </div>

              

                 <div class="col-12 col-md-12">
                  <div class="contact__info">
                    <p><span>Hotline: </span>Sri Lankan Time (9am - 6pm)</p>
                    <p><span>Email us: </span>suyeemarket@gmail.com</p>
                    <p class="contact__info__phone">+1 (973) 341 0582</p>
                    <p class="contact__info__phone">+94 (768) 514 527</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-5">
              <form @submit.prevent="onSubmit()">
                <div class="contact__form">
                  <h3 class="contact__title">Contact Form</h3>
                  <p>
                    please send us a message by filling out the form below and
                    we will get back with you shortly.
                  </p>
                  <div class="form-row">
                    <div class="col-12 form-group--block">
                      <label>Name: <span>*</span></label>
                      <input class="form-control" type="text" :class="{ 'is-invalid': $errors.has('name') }" v-model="contactForm.name" />
                      <div class="invalid-feedback" v-if="$errors.has('name')">
                        {{ $errors.first("name") }}
                      </div>
                    </div>
                    <div class="col-12 form-group--block">
                      <label>Email: <span>*</span></label>
                      <input class="form-control" type="text" :class="{ 'is-invalid': $errors.has('email') }" v-model="contactForm.email" />
                      <div class="invalid-feedback" v-if="$errors.has('email')">
                        {{ $errors.first("email") }}
                      </div>
                    </div>
                    <div class="col-12 form-group--block">
                      <label>Subject: </label>
                      <input class="form-control" :class="{ 'is-invalid': $errors.has('subject') }" type="text" v-model="contactForm.subject" />
                      <div class="invalid-feedback" v-if="$errors.has('subject')">
                        {{ $errors.first("subject") }}
                      </div>
                    </div>
                    <div class="col-12 form-group--block">
                      <label>Message: <span>*</span></label>
                      <textarea class="form-control" :class="{ 'is-invalid': $errors.has('message') }" v-model="contactForm.message"></textarea>
                       <div class="invalid-feedback" v-if="$errors.has('message')">
                        {{ $errors.first("message") }}
                      </div>
                    </div>
                  </div>
                </div>
                <button class="btn ps-button contact__send" type="submit">
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { mapActions } from "vuex";
import Map from "./Components/Map";
export default {
  name: "About",
  data() {
    return {
      contactForm: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
    };
  },
  methods: {
    ...mapActions("account", ["addContact"]),

    onSubmit() {
      this.addContact(this.contactForm).then(() => {});
    },
  },

  components: {
    Map,
  },
};
</script>
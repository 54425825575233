<template>
  <div class="contact__map">
    <iframe
      src="https://maps.google.com/maps?q=jaffna%20town&t=&z=15&ie=UTF8&iwloc=&output=embed"
      width="600"
      height="400"
      style="border: 0"
      allowfullscreen=""
      aria-hidden="false"
      tabindex="0"
    ></iframe>
  </div>
</template>

